import React from 'react'
import "./footer.scss"

export default function Footer() {
    return (
        <footer>
            <h3>© 2020-2021 Guilherme Pimenta All Rights Reserved</h3>
        </footer>
    )
}
